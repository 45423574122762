<template>
  <v-layout ma3>
    <v-flex v-if="items.length">
      <div>
        <v-btn-toggle v-model="sortBy" mandatory>
          <v-btn
            :value="sortType.numeric"
            text
            large
            @click="sort(sortType.numeric)"
          >
            <fa-icon
              :icon="'sort-numeric-' + resolveIcon(sortType.numeric)"
            ></fa-icon>
          </v-btn>
          <v-btn
            :value="sortType.alpha"
            text
            large
            @click="sort(sortType.alpha)"
          >
            <fa-icon
              :icon="'sort-alpha-' + resolveIcon(sortType.alpha)"
            ></fa-icon>
          </v-btn>
        </v-btn-toggle>
        <apexchart
          width="100%"
          height="auto"
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </v-flex>
    <v-flex v-else>
      <div pa3>{{ statisticText }}</div>
    </v-flex>
  </v-layout>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "backup-chart",
  components: { apexchart: VueApexCharts },
  props: {
    items: Array,
    startDate: String,
    endDate: String
  },
  data() {
    const sortType = {
      alpha: "name",
      numeric: "count"
    };

    return {
      sortType,
      sortBy: sortType.numeric,
      descOrder: { name: false, count: true },
      statisticText: this.$t("backup.welcomText"),
      series: [
        {
          name: this.$t("backup.chart.xAxisTitle")
        }
      ],
      chartOptions: {
        chart: {
          locales: [
            {
              name: "pl",
              options: {
                toolbar: {
                  exportToSVG: `${this.$t("backup.download")} SVG`,
                  exportToPNG: `${this.$t("backup.download")} PNG`,
                  exportToCSV: `${this.$t("backup.download")} CSV`
                }
              }
            }
          ],
          defaultLocale: "pl"
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true
        },
        theme: {
          monochrome: {
            enabled: true,
            color: "#1976d2"
          }
        },
        yaxis: {
          title: {
            text: this.$t("backup.chart.yAxisTitle")
          },
          labels: {
            maxWidth: 500
          }
        },
        xaxis: {
          title: {
            text: this.$t("backup.chart.xAxisTitle")
          }
        }
      }
    };
  },
  watch: {
    items(newValue, oldValue) {
      this.updateChart(newValue);
    }
  },
  methods: {
    updateChart(items = this.items) {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          title: {
            text: this.$t("backup.chart.title", {
              startDate: this.startDate,
              endDate: this.endDate
            }),
            align: "center"
          }
        }
      };

      const itemsCopy = items.slice(0);
      itemsCopy.sort(
        (a, b) =>
          (a[this.sortBy] > b[this.sortBy]) - (a[this.sortBy] < b[this.sortBy])
      );
      if (this.descOrder[this.sortBy]) itemsCopy.reverse();

      this.series[0].data = itemsCopy.map(function(i) {
        return { x: i.name, y: i.count };
      });
    },
    sort(sortBy) {
      if (sortBy === this.sortBy)
        this.descOrder[sortBy] = !this.descOrder[sortBy];
      this.sortBy = sortBy;
      this.updateChart();
    },
    resolveIcon(sortBy) {
      return this.descOrder[sortBy] ? "up" : "down";
    }
  }
};
</script>
