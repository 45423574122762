import axios from "../httpConfig/http.js";
import store from "../store/index";

const urlApi = "api";

export default {
  GetLogsStatistic(startTime, endTime) {
    return axios.get(
      `${urlApi}/backup/${startTime}/${endTime}/${store.state.user.login}`
    );
  },

  GetLogsCSV(startTime, endTime) {
    let link = document.createElement("a");
    link.href = `${urlApi}/file/Backupcsv/${startTime}/${endTime}/${store.state.user.login}`;
    link.download = "export.csv";
    link.click();
  },

  DeleteLogs(startTime, endTime) {
    return axios.delete(
      `${urlApi}/backup/${startTime}/${endTime}/${store.state.user.login}`
    );
  },
};
