<template>
  <v-container>
    <v-layout row>
      <v-flex xs2>
        <v-menu
          v-model="showDataPicker.start"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="startDate"
              :label="$t('backup.dateFrom')"
              readonly
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            locale="pl"
            color="primary"
            :max="endDate"
            @change="showDataPicker.start = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2>
        <v-menu
          v-model="showDataPicker.end"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="endDate"
              :label="$t('backup.dateTo')"
              readonly
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            locale="pl"
            color="primary"
            :min="startDate"
            :max="currentDate"
            @change="showDataPicker.end = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex>
        <v-btn class="light-button" @click="onSearchClick">
          <fa-icon icon="search" class="mr-2"></fa-icon>
          {{ $t("global.search") }}
        </v-btn>
      </v-flex>
    </v-layout>
    <br />
    <backup-chart
      :items="items"
      :start-date="startDate"
      :end-date="endDate"
    ></backup-chart>
    <v-layout>
      <v-btn class="action-button light-button" @click="getCsv">
        {{ $t("backup.getFileRange") }}
      </v-btn>
      <ConfirmModal @confirm-modal="confirmModal">
        <template v-slot:default="{ on }">
          <v-btn class="action-button light-button" v-on="on">
            {{ $t("backup.deleteRange") }}
          </v-btn>
        </template>
      </ConfirmModal>
    </v-layout>
  </v-container>
</template>
<script>
//import { mapState } from 'vuex';
import moment from "moment";
import dateTimeFilters from "../mixins/date-time-filters";
import BackupService from "../services/BackupService";
import ConfirmModal from "../components/ConfirmModal";
import { EmitSuccess } from "../event-bus";
import BackupChart from "../components/BackupChart";
export default {
  name: "Backup",
  components: { ConfirmModal, BackupChart },
  data() {
    const currentDate = dateTimeFilters.filters.dateFilter(moment());

    return {
      currentDate,
      startDate: currentDate,
      endDate: currentDate,
      showDataPicker: { start: false, end: false },
      items: []
    };
  },
  computed: {},
  methods: {
    confirmModal() {
      this.onRemoveConfirmed();
    },
    onRemoveConfirmed() {
      BackupService.DeleteLogs(this.startDate, this.endDate).then(response => {
        if (response.data == true) {
          EmitSuccess(this.$t("global.operationSuccess"));
        }
      });
    },
    onSearchClick() {
      BackupService.GetLogsStatistic(this.startDate, this.endDate).then(
        response => {
          this.items = response;
        }
      );
    },
    getCsv() {
      BackupService.GetLogsCSV(this.startDate, this.endDate);
    }
  }
};
</script>
<style scoped lang="scss">
.action-button {
  line-height: 0px;
  background-color: var(--v-primary-base);
}
</style>
